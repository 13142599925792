@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--color-primary: '#85DCC9';
	--color-secondary: '#AAD2B6';
	--color-neutral-dark: '#656565';
	--color-neutral-light: '#BCBFA2';
	--color-accent: '#AB9577';
}

body {
	font-family: 'Merriweather', sans-serif !important;
	overflow-x: hidden;
	word-break: break-word;
	text-align: justify;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

.MuiTypography-root {
	font-family: 'Merriweather', sans-serif !important;
}
.section-header{
	margin-bottom: 3rem;
}
.section-large {
	width: 90vw;
	min-height: 50vh;
	margin-top: 2em;
	margin-bottom: 2em;
}

.section-medium {
	width: 90vw;
	min-height: 25vh;
	margin-top: 2em;
	margin-bottom: 2em;
}

.navbar,
.footer,
.footer-card {
	height: 5rem !important;
}

.items-centered {
	place-content: center;
}

main {
	min-height: calc(100vh - 10rem) !important;
}

.custom-dropdown-menu {
	margin-top: px !important;
}

.text-centered {
	text-align: justify;
}

.mobile-nav-item {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.MuiLink-root, link, .external-link, a {
	color: #46bc69;
}

.grecaptcha-badge {
	visibility: hidden;
}

.centered-box {
	text-align: justify;
}
a:hover{
	text-decoration: none !important;
}

.pricedescription{
	color: #46bc69;
}

.subskill{
	margin-bottom: 0;
	margin-left: 0.5rem;
}

.footer-texts{
	font-size: 0.8rem;
}

.datePicker {
	width: 100%;
  }
  
  .datePickerPopper {
	z-index: 10; /* Ensure the popper has a higher z-index to appear above other elements */
  }

  .offerHeader{
	min-height: 3.3rem !important;
  }
  .offerBody{
	height: 15rem !important;
	overflow-y: auto;
  }